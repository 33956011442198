import logo from './logo.webp';
import './GoogleLogin.css';
import axios from 'axios';


function GoogleLogin() {

    const handleGoogleSignIn = () => {
        window.location.href = '/auth/google';
    };
	
  return (
    <div className="GoogleLogin">
      <header className="GoogleLogin-header">
        <img src={logo} className="GoogleLogin-logo" alt="logo" />
      </header>
	  <h1>Sign in</h1>
      <div>
          <button onClick={handleGoogleSignIn} style={{ padding: '10px 20px', fontSize: '16px', backgroundColor: '#4285F4', color: 'white', border: 'none', borderRadius: '5px', cursor: 'pointer' }}>
              Sign in with Google
          </button>
      </div>
    </div>
  );
}

export default GoogleLogin;
