import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom/client';
import axios from 'axios';
import './index.css';
import GoogleLogin from './GoogleLogin';
import Dashboard from './Dashboard';
import reportWebVitals from './reportWebVitals';
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
  Outlet
} from 'react-router-dom';

const root = ReactDOM.createRoot(document.getElementById('root'));

function App() {
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  useEffect(() => {
    // Check if the user is authenticated
    axios.get('/api/isAuthenticated')
      .then(response => {
        setIsAuthenticated(response.data.authenticated);
      })
      .catch(() => {
        setIsAuthenticated(false);
      });
  }, []);

  return (
    <Router>
      <Routes>
        <Route
          path="/"
          element={!isAuthenticated ? <GoogleLogin /> : <Navigate to="/dashboard" />}
        />
        <Route
          element={isAuthenticated ? <AuthenticatedLayout /> : <Navigate to="/" />}
        >
          <Route path="/dashboard" element={<Dashboard />} />
        </Route>
      </Routes>
    </Router>
  );
}

function AuthenticatedLayout() {
  return (
    <div>
      {/* You could add a shared navigation bar or other components here */}
      <Outlet />
    </div>
  );
}


root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
