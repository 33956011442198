import logo from './logo.webp';
import './Dashboard.css';
import axios from 'axios';

function Dashboard() {
    const handleToggleGate = () => {
        axios.get('/toggle-gate')
            .then(response => {
                alert('Gate toggled successfully!');
            })
            .catch(error => {
                alert('Error toggling gate: ' + error.message);
            });
    };

    const handleGoogleSignOut = () => {
        window.location.href = '/logout';
    };

    return (
        <div className="Dashboard">
            <header className="Dashboard-header">
                <img src={logo} className="Dashboard-logo" alt="logo" />
            </header>
            <h1>Gate Control Dashboard</h1>
            <button onClick={handleToggleGate} style={{ padding: '10px 20px', fontSize: '16px', marginBottom: '20px' }}>
                Toggle Gate
            </button>
			<p></p>
            <div>
                <button onClick={handleGoogleSignOut} style={{ padding: '10px 20px', fontSize: '16px', backgroundColor: '#4285F4', color: 'white', border: 'none', borderRadius: '5px', cursor: 'pointer' }}>
                    Sign out of Google
                </button>
            </div>
        </div>
    );
}

export default Dashboard;
